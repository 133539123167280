var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "fill-height px-5 py-3", attrs: { rounded: "lg" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-h6 justify-center pa-0 pb-3 text-center",
              attrs: { cols: "12" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "deskAssessment.results.report.summary.nextSteps.title"
                  )
                )
              )
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            _vm._l(_vm.steps, function(step) {
              return _c(
                "div",
                {
                  key: step.id,
                  staticClass: "d-flex justify-start noTextHighlight",
                  staticStyle: { width: "100%" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-img", {
                        attrs: {
                          id: "iconImage" + step.id,
                          src: require("@/assets/images/icons/" + step.icon),
                          width: _vm.iconSize,
                          height: _vm.iconSize
                        }
                      }),
                      !_vm.isLastStep(step)
                        ? _c("div", { staticClass: "dividerStyle" })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-4 pt-2 text-start text-body-2 vitrueDarkGrey--text",
                      staticStyle: { "min-width": "80px" },
                      attrs: { id: "text" + step.id }
                    },
                    [
                      _c("i18n", {
                        attrs: { path: step.text },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "painCoach",
                              fn: function() {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        color: "transparent"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "primary--text"
                                                      },
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "deskAssessment.results.report.summary.nextSteps.painCoach"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("pain-coach-info")],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }