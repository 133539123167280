var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { "max-width": "350px" } },
    [
      _c("v-img", {
        staticClass: "imageRadius",
        attrs: {
          src: require("@/assets/images/onboardingTour/wellnessOnboarding.jpg"),
          width: "100%",
          contain: ""
        }
      }),
      _c("v-card-title", { staticClass: "primary--text" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "deskAssessment.results.report.summary.nextSteps.tooltip.title"
            )
          )
        )
      ]),
      _c("v-card-text", { staticClass: "text-caption vitrueDarkGrey--text" }, [
        _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "deskAssessment.results.report.summary.nextSteps.tooltip.info"
                )
              ) +
              " "
          )
        ]),
        _c("div", { staticClass: "text-center text-subtitle-2 pt-4" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "deskAssessment.results.report.summary.nextSteps.tooltip.action"
                )
              ) +
              " "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }