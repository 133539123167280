<template>
  <v-card max-width="350px">
    <v-img
      class="imageRadius"
      :src="require('@/assets/images/onboardingTour/wellnessOnboarding.jpg')"
      width="100%"
      contain
    />

    <v-card-title class="primary--text">{{
      $t("deskAssessment.results.report.summary.nextSteps.tooltip.title")
    }}</v-card-title>

    <v-card-text class="text-caption vitrueDarkGrey--text">
      <div>
        {{ $t("deskAssessment.results.report.summary.nextSteps.tooltip.info") }}
      </div>
      <div class="text-center text-subtitle-2 pt-4">
        {{
          $t("deskAssessment.results.report.summary.nextSteps.tooltip.action")
        }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped>
.theme--light .v-card {
  border: 3px;
  border-radius: 15px;
  border-style: solid !important;
  border-color: var(--v-primary-base) !important;
}

.imageRadius {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}
</style>
