var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "fill-height px-5 py-3", attrs: { rounded: "lg" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height" },
        [
          _c(
            "v-col",
            {
              staticClass: "text-h6 pa-0 py-3 text-center",
              attrs: { cols: "12" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "deskAssessment.results.report.summary.whyThisMatters.title"
                  )
                )
              )
            ]
          ),
          _vm._l(_vm.sections, function(section) {
            return _c(
              "v-col",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.text[section.name],
                    expression: "text[section.name]"
                  }
                ],
                key: section.name,
                attrs: { cols: "12" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "auto" } },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/images/icons/" +
                                      section.icon),
                                    height: "25px",
                                    width: "25px",
                                    contain: ""
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "text-body-1 pa-0 font-weight-medium",
                                attrs: { cols: "auto", "align-self": "center" }
                              },
                              [
                                section.name !== "commonality"
                                  ? _c("i18n", {
                                      attrs: { path: section.title },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "goalText",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "secondary--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "deskAssessment.results.report.summary.whyThisMatters.sectionTitles.goal." +
                                                            _vm.userGoal
                                                              .stringValue
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.commonalityTitle))
                                    ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-body-2 vitrueDarkGrey--text",
                        attrs: { cols: section.image ? 9 : 12 }
                      },
                      [_vm._v(" " + _vm._s(_vm.text[section.name]) + " ")]
                    ),
                    section.image
                      ? _c(
                          "v-col",
                          { attrs: { cols: "3", "align-self": "center" } },
                          [
                            _c(
                              "v-row",
                              { attrs: { justify: "center" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c("v-img", {
                                      staticClass: "rounded-circle imageCircle",
                                      attrs: {
                                        id: "goalImage",
                                        src: require("@/assets/images/" +
                                          _vm.wellnessGoalImage),
                                        height: "80",
                                        width: "80",
                                        contain: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }