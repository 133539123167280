<template>
  <v-card rounded="lg" class="fill-height px-5 py-3">
    <v-row class="fill-height">
      <v-col cols="12" class="text-h6 pa-0 py-3 text-center">{{
        $t("deskAssessment.results.report.summary.whyThisMatters.title")
      }}</v-col>
      <v-col
        v-for="section in sections"
        :key="section.name"
        cols="12"
        v-show="text[section.name]"
      >
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col class="py-0" cols="auto">
                <v-img
                  :src="require(`@/assets/images/icons/${section.icon}`)"
                  height="25px"
                  width="25px"
                  contain
                />
              </v-col>
              <v-col
                cols="auto"
                class="text-body-1 pa-0 font-weight-medium"
                align-self="center"
              >
                <i18n
                  v-if="section.name !== 'commonality'"
                  :path="section.title"
                >
                  <template v-slot:goalText>
                    <span class="secondary--text">{{
                      $t(
                        `deskAssessment.results.report.summary.whyThisMatters.sectionTitles.goal.${userGoal.stringValue}`
                      )
                    }}</span>
                  </template>
                </i18n>
                <span v-else>{{ commonalityTitle }}</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            class="text-body-2 vitrueDarkGrey--text"
            :cols="section.image ? 9 : 12"
          >
            {{ text[section.name] }}
          </v-col>
          <v-col v-if="section.image" cols="3" align-self="center">
            <v-row justify="center">
              <v-col cols="auto" class="pa-0">
                <v-img
                  id="goalImage"
                  class="rounded-circle imageCircle"
                  :src="require(`@/assets/images/${wellnessGoalImage}`)"
                  height="80"
                  width="80"
                  contain
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import WellnessGoalImages from "@/assets/json/wellness/WellnessGoalImages.json";
import SummaryService from "@/services/deskassessment/desk-assessment-summary.js";
import { FocusArea } from "@/services/deskassessment/focus-areas";
import PainAreas from "@/components/painareas/PainAreasMale.json";

export default {
  name: "WhyThisMattersCard",
  props: {
    results: Object
  },
  data() {
    return {};
  },
  computed: {
    userGoal() {
      return SummaryService.GetSuggestedUserGoal(this.results);
    },
    wellnessGoalImage() {
      if (this.userGoal.stringValue === FocusArea.ReducePain.stringValue) {
        return WellnessGoalImages.ReducePain.noArea;
      }
      return WellnessGoalImages[this.userGoal.stringValue];
    },
    text() {
      return SummaryService.GetWhyItMattersText(
        this.results,
        this.userGoal.stringValue
      );
    },
    commonalityTitle() {
      let area = SummaryService.GetPriorityPainAreaOrDefault(this.results);
      let group = PainAreas[area].group;
      return this.$t(
        `deskAssessment.results.report.summary.whyThisMatters.sectionTitles.commonality.${group}`
      );
    },
    sections() {
      return [
        {
          name: "goal",
          icon: "target.svg",
          title:
            "deskAssessment.results.report.summary.whyThisMatters.sectionTitles.goal.heading",
          image: true
        },
        {
          name: "risk",
          icon: "alertTriangle.svg",
          title:
            "deskAssessment.results.report.summary.whyThisMatters.sectionTitles.risks"
        },
        {
          name: "impact",
          icon: "globe.svg",
          title:
            "deskAssessment.results.report.summary.whyThisMatters.sectionTitles.impact"
        },
        {
          name: "commonality",
          icon: "pie.svg"
        }
      ];
    }
  }
};
</script>

<style scoped>
.imageCircle {
  box-shadow: 0px 0px 10px 1px var(--v-disabled-base);
}
</style>
