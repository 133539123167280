<template>
  <v-card rounded="lg" class="fill-height px-5 py-3">
    <v-row class="fill-height" no-gutters>
      <v-col cols="12" class="text-h6 justify-center pa-0 pb-3 text-center">{{
        $t("deskAssessment.results.report.summary.nextSteps.title")
      }}</v-col>
      <v-col cols="12">
        <div
          v-for="step in steps"
          :key="step.id"
          class="d-flex justify-start noTextHighlight"
          style="width: 100%"
        >
          <div>
            <v-img
              :id="'iconImage' + step.id"
              :src="require('@/assets/images/icons/' + step.icon)"
              :width="iconSize"
              :height="iconSize"
            >
            </v-img>
            <div v-if="!isLastStep(step)" class="dividerStyle"></div>
          </div>

          <div
            :id="'text' + step.id"
            class="ml-4 pt-2 text-start text-body-2 vitrueDarkGrey--text"
            style="min-width: 80px"
          >
            <i18n :path="step.text">
              <template v-slot:painCoach>
                <v-tooltip bottom color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="primary--text">{{
                      $t(
                        "deskAssessment.results.report.summary.nextSteps.painCoach"
                      )
                    }}</span>
                  </template>
                  <pain-coach-info />
                </v-tooltip>
              </template>
            </i18n>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PainCoachInfo from "@/components/deskassessment/report/content/summary/PainCoachInfo.vue";
export default {
  name: "NextStepsCard",
  components: {
    PainCoachInfo
  },
  data() {
    return {
      iconSize: 40
    };
  },
  props: {
    hasPain: Boolean
  },
  computed: {
    steps() {
      return [
        {
          id: "read",
          icon: "readIconComplete.svg",
          text: `deskAssessment.results.report.summary.nextSteps.steps.1.${
            this.hasPain ? "pain" : "noPain"
          }`
        },
        {
          id: "exercise",
          icon: "exerciseIcon.svg",
          text: `deskAssessment.results.report.summary.nextSteps.steps.2.${
            this.hasPain ? "pain" : "noPain"
          }`
        },
        {
          id: "like",
          icon: "likeIcon.svg",
          text: `deskAssessment.results.report.summary.nextSteps.steps.3.${
            this.hasPain ? "pain" : "noPain"
          }`
        }
      ];
    }
  },
  methods: {
    isLastStep(step) {
      return this.steps[this.steps.length - 1].id === step.id;
    }
  }
};
</script>

<style scoped>
.dividerStyle {
  position: relative;
  right: 20px;
  border-right: 1px dashed var(--v-primary-base) !important;
  height: 40px;
  z-index: 3;
}
</style>
