<template>
  <v-card rounded="lg" class="px-5 py-3 fill-height">
    <v-row class="fill-height" no-gutters>
      <v-col cols="12" class="text-h6 justify-center pa-0 pb-3 text-center"
        ><v-row justify="center" align="center" no-gutters
          ><v-col cols="auto" class="pr-4"
            ><v-row justify="center" align="center" no-gutters
              ><v-img
                width="24"
                height="24"
                contain
                :src="
                  require('@/assets/images/icons/helpCircle.svg')
                " /></v-row></v-col
          ><v-col cols="auto">{{
            $vuetify.breakpoint.lgAndUp
              ? $t(textPath + "title")
              : $t(textPath + "shortTitle")
          }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-body-2 vitrueDarkGrey--text"
        ><p class="mb-0" v-html="upperText"></p>
        <v-row justify="center" class="py-1"
          ><v-col cols="auto"
            ><v-img
              id="focusAreaImage"
              class="imageCircle"
              width="80"
              height="80"
              contain
              :src="require(`@/assets/images/${painAreaImage}`)" /></v-col
        ></v-row>
        <p class="mb-0" v-html="lowerText"></p
      ></v-col>
    </v-row>
  </v-card>
</template>

<script>
import WellnessGoalImages from "@/assets/json/wellness/WellnessGoalImages.json";
import SummaryService from "@/services/deskassessment/desk-assessment-summary.js";
import * as Constants from "@/constants/riskFactorConstants.js";

export default {
  name: "WhatThisMeans",
  data() {
    return {
      textPath: "deskAssessment.results.report.summary.whatThisMeans."
    };
  },
  props: {
    results: Object
  },
  computed: {
    focusedArea() {
      if (!this.results) {
        return;
      }
      return SummaryService.GetPriorityPainAreaOrDefault(this.results);
    },
    painAreaImage() {
      if (!this.focusedArea) {
        return WellnessGoalImages.ReducePain[Constants.defaultPriorityPainArea];
      }
      return WellnessGoalImages.ReducePain[this.focusedArea];
    },
    upperText() {
      if (!this.results) {
        return "";
      }

      return SummaryService.GetWhatItMeansTopText(this.results);
    },
    lowerText() {
      if (!this.results) {
        return "";
      }

      return SummaryService.GetWhatItMeansBottomText(this.results);
    }
  }
};
</script>

<style scoped>
.imageCircle {
  border-radius: 50%;
  box-shadow: 0px 0px 10px 1px var(--v-disabled-base);
}
</style>
