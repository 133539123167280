<template>
  <v-card rounded="lg" class="fill-height px-5 py-3">
    <v-row class="fill-height" no-gutters>
      <v-col cols="12" class="text-h6 justify-center pa-0 pb-3 text-center">{{
        $t("deskAssessment.results.report.summary.score.title")
      }}</v-col>
      <v-col cols="12"
        ><v-row no-gutters>
          <v-col cols="12" class="mb-10 text-body-2 text-center">
            <i18n :path="scoreTranslation">
              <template v-slot:personalScore>
                <span :class="`${scoreColour}--text text-subtitle-2`"
                  >{{ score }}%</span
                >
              </template>
              <template v-if="notEqual" v-slot:differenceFromIndustryAverage>
                <span class="text-subtitle-2">
                  {{ Math.abs(industryAverage - score) }}% {{ higherLowerText }}
                </span>
              </template>
            </i18n>
          </v-col>
          <v-col cols="12" class="mt-10">
            <v-slider
              :value="`${score}`"
              :color="scoreColour"
              readonly
              hide-details
              min="0"
              max="100"
              track-color="transparent"
              track-fill-color="transparent"
              thumb-label="always"
            >
              <template v-slot:thumb-label>
                <span
                  style="position: absolute; width: 65px; left: -20px"
                  :style="spanish ? 'top: -45px;' : 'top: -25px;'"
                  class="black--text text-caption text-center"
                  >{{
                    $t("deskAssessment.results.report.summary.score.yourScore")
                  }}</span
                >{{ score }}%</template
              >
            </v-slider>
            <div id="industryAverageParent">
              <div class="arrow industryAverage"></div>
              <div
                class="industryAverage text-body-2 vitrueDarkGrey--text text-center"
              >
                {{ industryAverage }}%
              </div>
              <div
                class="industryAverage text-caption vitrueDarkGrey--text text-center"
              >
                {{
                  $t(
                    "deskAssessment.results.report.summary.score.industryAverage"
                  )
                }}
              </div>
            </div>
          </v-col>
        </v-row></v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import { getIndustryAverageDeskScore } from "@/customApi";

import Vue from "vue";
export default {
  name: "OverallScore",
  props: {
    score: Number
  },
  data() {
    return {
      industryAverage: 71 // retrieved from sql query
    };
  },
  async created() {
    window.addEventListener("resize", this.positionElements);
    try {
      let averageDeskScore = await getIndustryAverageDeskScore();
      this.industryAverage = averageDeskScore;
    } catch (ex) {
      this.$logger.captureException(ex);
    }
  },
  mounted() {
    Vue.nextTick(() => {
      this.positionElements();
    });
  },
  watch: {
    industryAverage() {
      this.positionElements();
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.positionElements);
  },
  methods: {
    positionElements() {
      if (this.industryAverage) {
        for (const element of document.getElementsByClassName(
          "industryAverage"
        )) {
          this.positionElement(element);
        }
      }
    },
    positionElement(element) {
      let parent = document.getElementById("industryAverageParent");
      const parentWidth = parent.offsetWidth;
      const width = element.offsetWidth;
      const rawPosition = (parentWidth * this.industryAverage) / 100;

      const xPosition = Math.min(
        Math.max(rawPosition - width / 2, 0),
        parentWidth - width
      );
      element.style.transform = `translateX(${xPosition}px)`;
    }
  },
  computed: {
    spanish() {
      return this._i18n.locale.includes("es");
    },
    notEqual() {
      return this.score !== this.industryAverage;
    },
    scoreTranslation() {
      if (this.notEqual) {
        return "deskAssessment.results.report.summary.score.scoreComparison.notEqual";
      } else {
        return "deskAssessment.results.report.summary.score.scoreComparison.equal";
      }
    },
    higherLowerText() {
      if (this.score > this.industryAverage) {
        return this.$t(
          "deskAssessment.results.report.summary.score.scoreComparison.higher"
        );
      } else {
        return this.$t(
          "deskAssessment.results.report.summary.score.scoreComparison.lower"
        );
      }
    },
    scoreColour() {
      if (this.score <= 25) {
        return "sliderRed";
      } else if (this.score <= 50) {
        return "sliderOrange";
      } else if (this.score <= 75) {
        return "sliderLightGreen";
      } else {
        return "sliderGreen";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  border-radius: 10px;
  height: 16px;
  width: 100%;
  background: linear-gradient(
    90deg,
    var(--v-sliderRed-base) 0%,
    var(--v-sliderRed-base) 25%,
    var(--v-sliderOrange-base) 25%,
    var(--v-sliderOrange-base) 50%,
    var(--v-sliderLightGreen-base) 50%,
    var(--v-sliderLightGreen-base) 75%,
    var(--v-sliderGreen-base) 75%,
    var(--v-sliderGreen-base) 100%
  ) !important;
}
/*noinspection CssUnusedSymbol*/
::v-deep .v-slider__thumb {
  border-radius: 50% 50% 0;
  box-shadow: 0px 0px 2px 0px white inset !important;
  transform: rotate(225deg) translateY(8px) translateX(8px);
}
/*noinspection CssUnusedSymbol*/
::v-deep .v-slider__track-container {
  height: 16px !important;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--v-vitrueDarkGrey-base);
}

.industryAverage {
  width: -webkit-fit-content;
  height: -webkit-fit-content;
  width: -moz-fit-content;
  height: -moz-fit-content;
  width: fit-content;
  height: fit-content;
}
</style>
