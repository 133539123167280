var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "fill-height px-5 py-3", attrs: { rounded: "lg" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-h6 justify-center pa-0 pb-3 text-center",
              attrs: { cols: "12" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("deskAssessment.results.report.summary.insights.title")
                )
              )
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                _vm._l(_vm.orderedScores, function(score) {
                  return _c(
                    "v-col",
                    {
                      key: score.name,
                      staticClass: "my-4",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: "",
                            color: "transparent",
                            disabled: _vm.isCategoryDefined(score)
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-row",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            style: _vm.isCategoryDefined(score)
                                              ? "cursor:context-menu;"
                                              : ""
                                          },
                                          "v-row",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-body-2",
                                            class: _vm.isCategoryDefined(score)
                                              ? ""
                                              : "disabled--text",
                                            staticStyle: {
                                              "min-width": "100px"
                                            },
                                            attrs: {
                                              id: score.name + "Title",
                                              cols: "auto"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "deskAssessment.results.summaryContent.insights." +
                                                      score.name
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-body-2 px-0",
                                            class: _vm.isCategoryDefined(score)
                                              ? "primary--text"
                                              : "disabled--text",
                                            staticStyle: {
                                              "min-width": "50px"
                                            },
                                            attrs: {
                                              id: score.name + "Score",
                                              cols: "auto",
                                              "align-self": "center"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.isCategoryDefined(score)
                                                    ? score.value + "%"
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "flex-grow-1 flex-shrink-0",
                                            staticStyle: {
                                              "min-width": "100px",
                                              "max-width": "100%"
                                            },
                                            attrs: {
                                              cols: "1",
                                              "align-self": "center"
                                            }
                                          },
                                          [
                                            _c("v-progress-linear", {
                                              attrs: {
                                                value: _vm.sliderValue(score),
                                                rounded: "",
                                                height: "8",
                                                "background-color":
                                                  "vitrueMidGrey",
                                                color: _vm.isCategoryDefined(
                                                  score
                                                )
                                                  ? "primary"
                                                  : "disabled"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t(
                                "deskAssessment.results.report.summary.insights.notAnsweredTooltip"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._l(_vm.orderedScores, function(score) {
                return _c("div", { key: score.name, staticClass: "my-2" })
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }