var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _vm.highRiskWarning
        ? _c(
            "v-col",
            { staticClass: "mb-0 pb-0", attrs: { cols: "12" } },
            [
              _c(
                "v-alert",
                {
                  staticClass: "pa-1 ma-0",
                  attrs: { id: "highRiskWarning", type: "warning", text: "" }
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.highRiskWarning) }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { xl: "4", lg: "4", md: "6", sm: "12", cols: "12" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "mb-5", attrs: { cols: "12" } },
                [
                  _c("overall-score", {
                    attrs: { score: Math.round(_vm.overallScore) }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("what-this-means", { attrs: { results: _vm.results } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { xl: "4", lg: "4", md: "6", sm: "12", cols: "12" } },
        [_c("why-this-matters-card", { attrs: { results: _vm.results } })],
        1
      ),
      _c(
        "v-col",
        { attrs: { xl: "4", lg: "4", md: "12", sm: "12", cols: "12" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.$vuetify.breakpoint.md ? "pr-3" : "mb-5",
                  attrs: { cols: "12", md: "6", lg: "12" }
                },
                [
                  _c("personal-insights-card", {
                    attrs: {
                      postureScore: _vm.posturePercentage,
                      habitsScore: _vm.habitsPercentage,
                      setupScore: _vm.setupPercentage,
                      healthScore: _vm.healthPercentage
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  class: _vm.$vuetify.breakpoint.md ? "pl-3" : "",
                  attrs: { cols: "12", md: "6", lg: "12" }
                },
                [_c("next-steps-card", { attrs: { hasPain: _vm.hasPain } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }