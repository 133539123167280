var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 py-3 fill-height", attrs: { rounded: "lg" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-h6 justify-center pa-0 pb-3 text-center",
              attrs: { cols: "12" }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    justify: "center",
                    align: "center",
                    "no-gutters": ""
                  }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-4", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "center",
                            align: "center",
                            "no-gutters": ""
                          }
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              width: "24",
                              height: "24",
                              contain: "",
                              src: require("@/assets/images/icons/helpCircle.svg")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.breakpoint.lgAndUp
                          ? _vm.$t(_vm.textPath + "title")
                          : _vm.$t(_vm.textPath + "shortTitle")
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-body-2 vitrueDarkGrey--text",
              attrs: { cols: "12" }
            },
            [
              _c("p", {
                staticClass: "mb-0",
                domProps: { innerHTML: _vm._s(_vm.upperText) }
              }),
              _c(
                "v-row",
                { staticClass: "py-1", attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-img", {
                        staticClass: "imageCircle",
                        attrs: {
                          id: "focusAreaImage",
                          width: "80",
                          height: "80",
                          contain: "",
                          src: require("@/assets/images/" + _vm.painAreaImage)
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("p", {
                staticClass: "mb-0",
                domProps: { innerHTML: _vm._s(_vm.lowerText) }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }