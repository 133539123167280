<template>
  <v-row justify="center">
    <v-col cols="12" v-if="highRiskWarning" class="mb-0 pb-0">
      <v-alert class="pa-1 ma-0" id="highRiskWarning" type="warning" text>
        <span v-html="highRiskWarning" />
      </v-alert>
    </v-col>
    <v-col xl="4" lg="4" md="6" sm="12" cols="12">
      <v-row class="fill-height" no-gutters
        ><v-col cols="12" class="mb-5">
          <overall-score :score="Math.round(overallScore)" /></v-col
        ><v-col cols="12"> <what-this-means :results="results" /></v-col>
      </v-row>
    </v-col>
    <v-col xl="4" lg="4" md="6" sm="12" cols="12">
      <why-this-matters-card :results="results" />
    </v-col>
    <v-col xl="4" lg="4" md="12" sm="12" cols="12">
      <v-row class="fill-height" no-gutters>
        <v-col
          cols="12"
          md="6"
          lg="12"
          :class="$vuetify.breakpoint.md ? 'pr-3' : 'mb-5'"
          ><personal-insights-card
            :postureScore="posturePercentage"
            :habitsScore="habitsPercentage"
            :setupScore="setupPercentage"
            :healthScore="healthPercentage"
        /></v-col>
        <v-col
          cols="12"
          md="6"
          lg="12"
          :class="$vuetify.breakpoint.md ? 'pl-3' : ''"
          ><next-steps-card :hasPain="hasPain"
        /></v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import OverallScore from "@/components/deskassessment/report/content/summary/OverallScore";
import WhatThisMeans from "@/components/deskassessment/report/content/summary/WhatThisMeans";
import NextStepsCard from "@/components/deskassessment/report/content/summary/NextStepsCard";
import PersonalInsightsCard from "@/components/deskassessment/report/content/summary/PersonalInsightsCard";
import WhyThisMattersCard from "@/components/deskassessment/report/content/summary/WhyThisMattersCard";
import * as Constants from "@/constants/constants.js";
import { viewingOwnResults } from "@/services/privacy-service.js";

export default {
  name: "SummaryPanel",
  components: {
    OverallScore,
    WhatThisMeans,
    WhyThisMattersCard,
    PersonalInsightsCard,
    NextStepsCard
  },
  props: {
    results: {}
  },
  computed: {
    overallScore: function () {
      if (this.savedScore) {
        return this.savedScore;
      }
      return DeskAssessmentUtil.getOverallScore(this.results);
    },
    healthScore() {
      return DeskAssessmentUtil.calculateHealthScore(this.results);
    },
    healthPercentage() {
      if (this.noHealthSection) {
        return null;
      }
      return DeskAssessmentUtil.getPercentageScore(
        DeskAssessmentUtil.calculateHealthScore(this.results),
        DeskAssessmentUtil.maxHealthScore
      );
    },
    habitsPercentage() {
      return DeskAssessmentUtil.getPercentageScore(
        DeskAssessmentUtil.calculateWorkHabitsScore(this.results),
        DeskAssessmentUtil.maxWorkHabitsScore
      );
    },
    setupPercentage() {
      return DeskAssessmentUtil.getStandaloneSetupPercentageScore(this.results);
    },
    posturePercentage() {
      if (!this.results.webcam.pictureTaken) {
        return null;
      }
      const score = DeskAssessmentUtil.getPercentageScore(
        DeskAssessmentUtil.calculateDeskSetupScore(this.results),
        DeskAssessmentUtil.getMaxWebcamScore(this.results)
      );
      return score;
    },
    deskSetupScore() {
      return DeskAssessmentUtil.calculateEquipmentPercentage(this.results);
    },
    noHealthSection() {
      return this.results.health.smoker === null || !this.results.health.bmi;
    },
    hasPain() {
      return Object.keys(this.results.pain.areas).length > 0;
    },
    highRiskWarning() {
      if (!this.hasPain || !this.viewingOwnResults) {
        return null;
      }

      let hasRedFlags = false;
      var areas = Object.keys(this.results.pain.areas);
      areas.forEach(area => {
        if (!hasRedFlags) {
        }
        hasRedFlags = DeskAssessmentUtil.hasRedFlags(
          area,
          this.results.pain.areas[area],
          Constants.assessmentTypes.deskAssessment
        );
      });

      if (hasRedFlags) {
        return this.$t("wellness.v2.redFlagsDisclaimer");
      }
      return null;
    },
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    }
  }
};
</script>
