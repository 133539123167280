var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "fill-height px-5 py-3", attrs: { rounded: "lg" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-h6 justify-center pa-0 pb-3 text-center",
              attrs: { cols: "12" }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("deskAssessment.results.report.summary.score.title")
                )
              )
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mb-10 text-body-2 text-center",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("i18n", {
                        attrs: { path: _vm.scoreTranslation },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "personalScore",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.scoreColour +
                                        "--text text-subtitle-2"
                                    },
                                    [_vm._v(_vm._s(_vm.score) + "%")]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            _vm.notEqual
                              ? {
                                  key: "differenceFromIndustryAverage",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "text-subtitle-2" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                Math.abs(
                                                  _vm.industryAverage -
                                                    _vm.score
                                                )
                                              ) +
                                              "% " +
                                              _vm._s(_vm.higherLowerText) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-10", attrs: { cols: "12" } },
                    [
                      _c("v-slider", {
                        attrs: {
                          value: "" + _vm.score,
                          color: _vm.scoreColour,
                          readonly: "",
                          "hide-details": "",
                          min: "0",
                          max: "100",
                          "track-color": "transparent",
                          "track-fill-color": "transparent",
                          "thumb-label": "always"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "thumb-label",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "black--text text-caption text-center",
                                    staticStyle: {
                                      position: "absolute",
                                      width: "65px",
                                      left: "-20px"
                                    },
                                    style: _vm.spanish
                                      ? "top: -45px;"
                                      : "top: -25px;"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "deskAssessment.results.report.summary.score.yourScore"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(_vm._s(_vm.score) + "%")
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("div", { attrs: { id: "industryAverageParent" } }, [
                        _c("div", { staticClass: "arrow industryAverage" }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "industryAverage text-body-2 vitrueDarkGrey--text text-center"
                          },
                          [_vm._v(" " + _vm._s(_vm.industryAverage) + "% ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "industryAverage text-caption vitrueDarkGrey--text text-center"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "deskAssessment.results.report.summary.score.industryAverage"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }