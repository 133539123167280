<template>
  <v-card rounded="lg" class="fill-height px-5 py-3">
    <v-row class="fill-height" no-gutters>
      <v-col cols="12" class="text-h6 justify-center pa-0 pb-3 text-center">{{
        $t("deskAssessment.results.report.summary.insights.title")
      }}</v-col>
      <v-col cols="12">
        <v-row no-gutters
          ><v-col
            cols="12"
            v-for="score in orderedScores"
            :key="score.name"
            class="my-4"
          >
            <v-tooltip
              bottom
              color="transparent"
              :disabled="isCategoryDefined(score)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-row
                  v-bind="attrs"
                  v-on="on"
                  :style="
                    isCategoryDefined(score) ? 'cursor:context-menu;' : ''
                  "
                >
                  <v-col
                    :id="`${score.name}Title`"
                    cols="auto"
                    style="min-width: 100px"
                    class="text-body-2"
                    :class="isCategoryDefined(score) ? '' : 'disabled--text'"
                  >
                    {{
                      $t(
                        `deskAssessment.results.summaryContent.insights.${score.name}`
                      )
                    }}
                  </v-col>
                  <v-col
                    :id="`${score.name}Score`"
                    cols="auto"
                    class="text-body-2 px-0"
                    :class="
                      isCategoryDefined(score)
                        ? 'primary--text'
                        : 'disabled--text'
                    "
                    align-self="center"
                    style="min-width: 50px"
                  >
                    {{ isCategoryDefined(score) ? `${score.value}%` : "" }}
                  </v-col>
                  <v-col
                    cols="1"
                    style="min-width: 100px; max-width: 100%"
                    class="flex-grow-1 flex-shrink-0"
                    align-self="center"
                  >
                    <v-progress-linear
                      :value="sliderValue(score)"
                      rounded
                      height="8"
                      background-color="vitrueMidGrey"
                      :color="isCategoryDefined(score) ? 'primary' : 'disabled'"
                    />
                  </v-col>
                </v-row>
              </template>
              <vitrue-tooltip
                :message="
                  $t(
                    'deskAssessment.results.report.summary.insights.notAnsweredTooltip'
                  )
                "
              />
            </v-tooltip>
          </v-col>
        </v-row>
        <div v-for="score in orderedScores" :key="score.name" class="my-2"></div
      ></v-col>
    </v-row>
  </v-card>
</template>

<script>
import * as Constants from "@/constants/constants.js";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "PersonalInsightsCard",
  components: {
    VitrueTooltip
  },
  data() {
    return {
      categories: Constants.deskAssessmentInsightCategories
    };
  },
  props: {
    postureScore: Number,
    habitsScore: Number,
    setupScore: Number,
    healthScore: Number
  },
  computed: {
    orderedScores() {
      let scores = [
        { name: "posture", value: this.postureScore },
        { name: "habit", value: this.habitsScore },
        { name: "setup", value: this.setupScore },
        { name: "health", value: this.healthScore }
      ];

      return scores.sort((a, b) => {
        let aDefined = this.isCategoryDefined(a);
        let bDefined = this.isCategoryDefined(b);

        if (aDefined && !bDefined) {
          return -1;
        }
        if (!aDefined && bDefined) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
    }
  },
  methods: {
    isCategoryDefined(score) {
      return Number.isInteger(score.value);
    },
    sliderValue(score) {
      if (!this.isCategoryDefined(score)) {
        return 0;
      }
      return score.value === 0 ? 1 : score.value;
    }
  }
};
</script>
